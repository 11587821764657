<template>
  <div class="g05-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-input
            type="text"
            v-model="searchModel.meterageDisplayCode"
            placeholder="子目号"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleBatchAddClick">生成数据</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleBatchDeleteClick">批量删除</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <my-el-table
      :data="g05List"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      :selection="true"
      keyFiled="g05Guid"
      :cell-class-name="cellClassName"
      @select-all-change="handleSelectAllChange"
      @selection-change="handleSelectionChange"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
      >
      <el-table-column fixed="left" prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.meterageDisplayCode" width="130" header-align="left" align="left" label="清单项目编号"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.meterageName" width="260" header-align="left" align="left" label="工程或费用项目名称"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.unit" width="60" header-align="center" align="center" label="单位"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.price" width="100" header-align="center" align="center" label="单价"></el-table-column>
      <el-table-column label="合同数量" header-align="center">
        <el-table-column prop="meterageList.num" width="120" header-align="center" align="center" label="原合同数量"></el-table-column>
        <el-table-column prop="meterageList.num" width="120" header-align="center" align="center" label="施工图数量"></el-table-column>
        <el-table-column prop="" width="120" header-align="center" align="center" label="变更数量"></el-table-column>
        <el-table-column prop="meterageList.num" width="120" header-align="center" align="center" label="变更后数量"></el-table-column>
        <el-table-column prop="meterageList.amount" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
      </el-table-column>
      <el-table-column label="本年计划" header-align="center">
        <el-table-column prop="currentYearNum" width="120" header-align="center" align="center" label="工程量"></el-table-column>
        <el-table-column prop="currentYearAmount" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
      </el-table-column>
      <el-table-column label="本年度累计完成" header-align="center">
        <el-table-column prop="totalYearNum" width="120" header-align="center" align="center" label="工程量"></el-table-column>
        <el-table-column prop="totalYearAmount" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
        <el-table-column prop="totalYearPercent" width="120" header-align="center" align="center" label="占年计划%"></el-table-column>
      </el-table-column>
      <el-table-column label="自开工起工程累计完成" header-align="center">
        <el-table-column prop="totalNum" width="120" header-align="center" align="center" label="工程量"></el-table-column>
        <el-table-column prop="totalAmount" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
        <el-table-column prop="totalPercent" width="120" header-align="center" align="center" label="占合同%"></el-table-column>
      </el-table-column>
      <el-table-column v-if="showAddBtn" fixed="right" width="50" label="操作" header-align="center" align="center">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" v-if="!scope.row.isLock" class="text-danger far fa-trash-alt operation-button"></i>
            <i v-else class="text-muted far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </my-el-table>

    <my-dialog
      ref="myDialog"
      title="选择草图"
      size="xl"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="handleOk"
      >
      <picture-index
        ref="pictureIndex"
        :selection="true"
        >
      </picture-index>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import PictureIndex from '@/views/PictureManage/PictureIndex'
import MyElTable from '@/components/MyElTable/MyElTable'
import G05Model from '@/model/G05Model'
import g05Service from '@/services/g05Service'
import PageOperation from '@/mixins/PageOperation'
import PageInfo from '@/support/PageInfo'
import { mapGetters } from 'vuex'
import auth from '@/common/auth'
import config from '@/config'

export default {
  name: 'G05Index',
  mixins: [
    PageOperation
  ],
  components: {
    PictureIndex,
    MyElTable,
    MyDialog,
    MySearchBar
  },
  filters: {
  },
  computed: {
    tenderGuid () {
      return auth.getUserInfo().tenderGuid
    },
    userGuid () {
      return auth.getUserInfo().userGuid
    },
    userIdentityGuid () {
      return auth.getUserInfo().identityGuid
    },
    couldLock () {
      return config.applyIdentities.indexOf(auth.getUserInfo().identityGuid) === -1
    },
    firstYear () {
      // TODO
      return this.year === 2020
    },
    showAddBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid
    },
    dataColumns () {
      if (this.g05List.length) {
        return this.g05List[0].children
      }
      return []
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  data () {
    return {
      testValue: 1,
      total: 0,
      g05List: [],
      g05Model: new G05Model(),
      searchModel: {
        planStatExaminationGuid: '',
        meterageDisplayCode: ''
      },
      selectedValues: [],
      totalAmount: ''
    }
  },
  methods: {
    cellClassName ({ row, column }) {
      if (!row.empty && column.property === 'totalPercent' && parseFloat(row[column.property]) > 100) {
        return 'warning'
      }
      return ''
    },
    handleBatchDeleteClick () {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      g05Service.batchDelete(this.selectedValues.join(','))
        .then(res => {
          if (res.data.code === 1) {
            this._getG01List()
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: '操作失败'
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败'
          })
        })
    },
    classFilter (column) {
      if (column.identityGuid === this.userIdentityGuid && this.userGuid === this.approvalUserGuid) {
        return 'editable'
      }
      return ''
    },
    handleBatchPicture (flag) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      if (flag) {
        // open image selection ui
        this.$refs.myDialog.open()
      } else {
        this.doBatchPicture('')
      }
    },
    handleOk () {
      if (!this.$refs.pictureIndex.getSelectedPicture()) {
        this.$message({
          type: 'warning',
          message: '请选择要绑定的草图！'
        })
        return
      }
      this.$refs.myDialog.close()
      this.doBatchPicture(this.$refs.pictureIndex.getSelectedPicture().pictureGuid)
    },
    doBatchPicture (pictureGuid) {
      g05Service.batchPicture(this.selectedValues, pictureGuid)
        .then(res => {
          if (res.data.code === 1) {
            this._getG05List()
            this.$message({
              type: 'success',
              message: (pictureGuid ? '绑定草图' : '删除草图') + '成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: (pictureGuid ? '绑定草图' : '删除草图') + '失败！'
          })
        })
    },
    handleBatchLock (isLocked) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      g05Service.batchLock(this.planStatExaminationGuid, this.selectedValues, isLocked)
        .then(res => {
          if (res.data.code === 1) {
            this._getG05List()
            this.$message({
              type: 'success',
              message: (isLocked ? '锁定' : '解锁') + '成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: (isLocked ? '锁定' : '解锁') + '失败！'
          })
        })
    },
    handleBlur (row, type = 0) {
      if (type) {
        console.log('g05Identity', row)
        this._approval(row)
      } else {
        this.g05Model = new G05Model(row)
        this.g05Model.currentMonthNum = parseFloat(row.currentMonthNum)
        this._edit()
      }
    },
    handleSearchClick () {
      this._getG05List()
    },
    handleBatchAddClick () {
      g05Service.batchAdd(this.tenderGuid, this.year, this.planStatExaminationGuid)
        .then(res => {
          if (res.data.code === 1) {
            this._getG05List()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '生成数据失败！'
          })
        })
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _g05Model = new G05Model(row)
          g05Service.delete(_g05Model.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getG05List()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleSelectionChange (newVal) {
      this.selectedValues = newVal
      console.log('selectedValues', this.selectedValues)
    },
    handleSelectAllChange (checkAll) {
      this.g05List.forEach(g05 => {
        g05.checked = checkAll
      })
    },
    _getG05List () {
      this.selectedValues = []
      this.searchModel.planStatExaminationGuid = this.planStatExaminationGuid
      g05Service.list(new PageInfo({ pageSize: this.pageSize, pageIndex: this.pageIndex, fieldOrder: '' }), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            this.g05List = res.data.data.map(g05 => {
              g05.checked = false
              return g05
            })
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _approval (g05Identity) {
      g05Service.approval(g05Identity.g05Guid, g05Identity.identityGuid, g05Identity.num, g05Identity.sortId, this.planStatExaminationGuid, this.periodName)
        .then(res => {
          if (res.data.code === 1) {
            this._getG05List()
            this.$message({
              type: 'success',
              message: '修改数量成功！'
            })
          } else {
            this._getG05List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this._getG05List()
          this.$message({
            type: 'error',
            message: '修改数量失败！'
          })
        })
    },
    _edit () {
      g05Service.edit(this.g05Model)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this._getG05List()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this._getG05List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this._getG05List()
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getG05List()
    this.refreshMethod = this._getG05List
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
::v-deep .editable {
  color: red;
}
::v-deep .warning {
  background-color: red;
}
</style>
