import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g05'

let batchAddUrl = 'batchAdd'

class G05Service extends RestService {
  batchAdd (tenderGuid, year, planStatExaminationGuid) {
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), utility.getFormData({
      tenderGuid,
      year,
      planStatExaminationGuid
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g05Service = new G05Service(resourceName)

export default g05Service
